<template>
  <div class="main" align="center">
    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
      >
        <tbody>
          <tr>
            <td>
              <table
                height="75"
                cellspacing="0"
                cellpadding="0"
                width="960"
                align="center"
                background="https://admin88.hdwdd.com/assets/images/33.jpg"
                border="0"
              >
                <tbody>
                  <br />
                  <!-- <tr>
                    <td background="https://admin88.hdwdd.com/assets/images/11.jpg" height="19"
                      style="line-height: 0"></td>
                  </tr> -->
                  <!-- <tr>
                    <td background="https://admin88.hdwdd.com/assets/images/44.jpg" height="14"
                      style="line-height: 0"></td>
                  </tr> -->
                  <tr>
                    <td height="38" align="center">
                      <img
                        border="0"
                        :src="'https://admin88.hdwdd.com/storage/' + logo"
                        width="920"
                        height="200"
                      />
                    </td>
                  </tr>
                  <!-- <tr>
                    <td background="https://admin88.hdwdd.com/assets/images/44.jpg" height="14"
                      style="line-height: 0">
                      <img height="19" src="https://admin88.hdwdd.com/assets/images/77.jpg" width="960" />
                    </td>
                  </tr> -->
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->

              <!--001-->
              <div>
                <div
                  class="KJ-TabBox"
                  style="height: 220px; margin-bottom: 20px"
                >
                  <ul>
                    <li
                      v-show="open_am == 1"
                      data-opt="{'color':'#ffffff','url':'https://zhibo.2020kj.com:777/amkj2.html','height':130}"
                      :class="cur == 1 ? 'cur' : ''"
                      style="
                        width: 220px;
                        text-align: center;
                        display: block;
                        flex: inherit;
                      "
                      @click="changeTab($event, 1)"
                    >
                      香港新彩
                    </li>
                    <li
                      v-show="open_hk == 0"
                      data-opt="{'color':'#ffffff','url':'https://zhibo.chong0123.com:777/xgkj2.html','height':130}"
                      :class="cur == 2 ? 'cur' : ''"
                      @click="changeTab($event, 2)"
                      style="
                        width: 220px;
                        text-align: center;
                        display: block;
                        flex: inherit;
                      "
                    >
                      香港六合彩
                    </li>
                    <li
                      v-show="open_hk == 0"
                      data-opt="{'color':'#ffffff','url':'https://zhibo.chong0123.com:777/xgkj2.html','height':130}"
                      :class="cur == 3 ? 'cur' : ''"
                      @click="changeTab($event, 3)"
                      style="
                        width: 220px;
                        text-align: center;
                        display: block;
                        flex: inherit;
                      "
                    >
                      澳门八点半
                    </li>
                  </ul>
                  <div class="cur" v-show="qihaokj == 0">
                    <div class="list" style="display: flex; flex-wrap: wrap">
                      <div
                        class="new_kj"
                        style="
                          width: 100vw;
                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          font-size: 20px;
                          font-weight: 600;
                          padding: 10px;
                        "
                      >
                        <span
                          >香港六合彩第<span class="qi" style="color: red">{{
                            ishkdata.issue | trimText
                          }}</span
                          >期最新开奖结果:</span
                        >
                      </div>
                      <ul
                        style="
                          display: flex;
                          flex-wrap: nowrap;
                          width: 86%;
                          margin: auto;
                          justify-content: space-around;
                          height: 70px;
                        "
                      >
                        <li

                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[0])
                            "
                            >{{ ishkdata.drawResult.split(",")[0] }}</span
                          >
                          <span class="c1">{{ replacedText[0] }}</span>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[1])
                            "
                            >{{ ishkdata.drawResult.split(",")[1] }}</span
                          >
                          <span class="c1">{{ replacedText[1] }}</span>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[2])
                            "
                            >{{ ishkdata.drawResult.split(",")[2] }}</span
                          >
                          <span class="c1">{{ replacedText[2] }}</span>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[3])
                            "
                            >{{ ishkdata.drawResult.split(",")[3] }}</span
                          >
                          <span class="c1">{{ replacedText[3] }}</span>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[4])
                            "
                            >{{ ishkdata.drawResult.split(",")[4] }}</span
                          >
                          <span class="c1">{{ replacedText[4] }}</span>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[5])
                            "
                            >{{ ishkdata.drawResult.split(",")[5] }}</span
                          >
                          <span class="c1">{{ replacedText[5] }}</span>
                        </li>
                        <li
                          style="display: flex;background-color: unset;wrap;justify-content: center;align-items: center;width:auto;height:auto"
                        >
                          <span class="icon icon-jia"></span>
                        </li>
                        <li
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                                  v-if="ishkdata.drawResult"
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(ishkdata.drawResult.split(',')[6])
                            "
                            >{{ ishkdata.drawResult.split(",")[6] }}</span
                          >
                          <span class="c1">{{ replacedText[6] }}</span>
                        </li>
                      </ul>
                      <div
                        style="
                          font-size: 16px;
                          padding: 35px 35px;

                          width: 100%;
                          overflow: hidden;
                          display: flex;
                          justify-content: space-between;
                          color: #ed9951;
                        "
                      >
                        <div>本期开奖时间:{{ ishkdata.drawTime }}</div>
                        <div style="text-align: right">
                          <router-link
                            style="color: #1ca386"
                            target="_blank"
                            :to="{ path: '/history', query: { type: cur } }"
                            >【开奖记录】</router-link
                          >
                        </div>
                      </div>

                      <div
                        class="next_kj"
                        style="
                          padding-top: 21px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          font-size: 32px;
                          display: none;
                        "
                      >
                        本期开奖时间为：<span>{{ ishkdata.drawTime }} </span>

                        <!-- <div class="" v-if="showTimeLeft">
                          倒计时:<span style="color: red">{{ date }}</span>
                        </div>
                        <div class="" v-else>开奖中</div> -->
                      </div>
                    </div>
                  </div>

                  <div class="cur" v-show="qihaokj == 1">
                    <!-- <iframe class="KJ-IFRAME" :src="actionUrl" width="100%" height="130" frameborder="0" scrolling="no"></iframe> -->
                    <div
                      id="xg"
                      class="list"
                      style="display: flex; flex-wrap: wrap"
                    >
                      <div
                        class="new_kj"
                        style="
                          width: 100vw;

                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          font-size: 20px;
                          font-weight: 600;
                          padding: 10px;
                        "
                        v-if="currentData.last"
                      >
                        <span
                          >第<span class="qi" style="color: red">{{
                            currentData.last.qisu
                          }}</span
                          >期最新开奖结果:</span
                        >
                      </div>
                      <ul
                        style="
                          display: flex;
                          flex-wrap: nowrap;
                          width: 86%;
                          margin: auto;
                          justify-content: space-around;
                          height: 70px;
                        "
                      >
                        <li
                          v-if="viewNumber >= 1"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma1.split(',')[0])
                            "
                            >{{ currentData.last.haoma1.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma1.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>正</li>
                        <li
                          v-if="viewNumber >= 2"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css20 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma2.split(',')[0])
                            "
                            >{{ currentData.last.haoma2.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma2.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>在</li>
                        <li
                          v-if="viewNumber >= 3"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css45 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma3.split(',')[0])
                            "
                            >{{ currentData.last.haoma3.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma3.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>开</li>
                        <li
                          v-if="viewNumber >= 4"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css25 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma4.split(',')[0])
                            "
                            >{{ currentData.last.haoma4.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma4.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>奖</li>
                        <li
                          v-if="viewNumber >= 5"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css39 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma5.split(',')[0])
                            "
                            >{{ currentData.last.haoma5.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma5.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 6"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css15 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma6.split(',')[0])
                            "
                            >{{ currentData.last.haoma6.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma6.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>请</li>
                        <li
                          v-if="viewNumber >= 7"
                          style="display: flex;background-color: unset;wrap;justify-content: center;align-items: center;width:auto;height:auto"
                        >
                          <span class="icon icon-jia"></span>
                        </li>
                        <li class="default no" v-else>稍</li>

                        <li
                          v-if="viewNumber >= 7"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css28 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma7.split(',')[0])
                            "
                            >{{ currentData.last.haoma7.split(",")[0] }}</span
                          >
                          <span class="c7">{{
                            currentData.last.haoma7.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>等</li>
                      </ul>
                      <div
                        style="
                          font-size: 16px;
                          padding: 35px 35px;

                          width: 100%;
                          overflow: hidden;
                          display: flex;
                          justify-content: space-between;
                          color: #ed9951;
                        "
                        v-if="currentData.last"
                      >
                        <div v-if="currentData.next">
                          第{{ currentData.next.qisu }}开奖:{{
                            Format(
                              "yyyy年MM月dd日 hh:mm:ss",
                              currentData.next.date_time
                            )
                          }}
                        </div>
                        <div class="" v-if="showTimeLeft">
                          倒计时:<span style="color: red">{{ date }}</span>
                        </div>
                        <div class="" v-else>开奖中</div>

                        <div style="text-align: right">
                          <router-link
                            style="color: #1ca386"
                            target="_blank"
                            :to="{ path: '/history', query: { type: cur } }"
                            >【开奖记录】</router-link
                          >
                        </div>
                      </div>

                      <div
                        class="next_kj"
                        style="
                          padding-top: 30px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          font-size: 16px;
                          display: none;
                        "
                        v-if="currentData.next"
                      >
                        第<span class="qi">{{ currentData.next.qisu }}</span
                        >开奖：<span id="nx"
                          >{{
                            Format(
                              "yyyy年MM月dd日 hh:mm:ss",
                              currentData.next.date_time
                            )
                          }} </span
                        >&nbsp;&nbsp;

                        <!-- <div class="" v-if="showTimeLeft">
                          倒计时:<span style="color: red">{{ date }}</span>
                        </div>
                        <div class="" v-else>开奖中</div> -->
                      </div>
                    </div>
                  </div>
                  <!-- 澳门八点半 -->
                  <div class="cur" v-show="qihaokj == 2">
                    <!-- <iframe class="KJ-IFRAME" :src="actionUrl" width="100%" height="130" frameborder="0" scrolling="no"></iframe> -->
                    <div
                      id="xg"
                      class="list"
                      style="display: flex; flex-wrap: wrap"
                    >
                      <div
                        class="new_kj"
                        style="
                          width: 100vw;

                          display: flex;
                          justify-content: flex-start;
                          align-items: center;
                          font-size: 20px;
                          font-weight: 600;
                          padding: 10px;
                        "
                        v-if="currentData.last"
                      >
                        <span
                          >第<span class="qi" style="color: red">{{
                            currentData.last.qisu
                          }}</span
                          >期最新开奖结果:</span
                        >
                      </div>
                      <ul
                        style="
                          display: flex;
                          flex-wrap: nowrap;
                          width: 86%;
                          margin: auto;
                          justify-content: space-around;
                          height: 70px;
                        "
                      >
                        <li
                          v-if="viewNumber >= 1"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css48 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma1.split(',')[0])
                            "
                            >{{ currentData.last.haoma1.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma1.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>正</li>
                        <li
                          v-if="viewNumber >= 2"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css20 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma2.split(',')[0])
                            "
                            >{{ currentData.last.haoma2.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma2.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>在</li>
                        <li
                          v-if="viewNumber >= 3"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css45 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma3.split(',')[0])
                            "
                            >{{ currentData.last.haoma3.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma3.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>开</li>
                        <li
                          v-if="viewNumber >= 4"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css25 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma4.split(',')[0])
                            "
                            >{{ currentData.last.haoma4.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma4.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>奖</li>
                        <li
                          v-if="viewNumber >= 5"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css39 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma5.split(',')[0])
                            "
                            >{{ currentData.last.haoma5.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma5.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>中</li>
                        <li
                          v-if="viewNumber >= 6"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css15 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma6.split(',')[0])
                            "
                            >{{ currentData.last.haoma6.split(",")[0] }}</span
                          >
                          <span class="c1">{{
                            currentData.last.haoma6.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>请</li>
                        <li
                          v-if="viewNumber >= 7"
                          style="display: flex;background-color: unset;wrap;justify-content: center;align-items: center;width:auto;height:auto"
                        >
                          <span class="icon icon-jia"></span>
                        </li>
                        <li class="default no" v-else>稍</li>

                        <li
                          v-if="viewNumber >= 7"
                          style="
                            display: flex;
                            flex-wrap: wrap;
                            justify-content: center;
                            align-items: center;
                            background: unset;
                          "
                        >
                          <span
                            class="icon css28 bg"
                            :style="
                              'width: 100%;' +
                              getUrl(currentData.last.haoma7.split(',')[0])
                            "
                            >{{ currentData.last.haoma7.split(",")[0] }}</span
                          >
                          <span class="c7">{{
                            currentData.last.haoma7.split(",")[1]
                          }}</span>
                        </li>
                        <li class="default" v-else>等</li>
                      </ul>
                      <div
                        style="
                          font-size: 16px;
                          padding: 35px 35px;

                          width: 100%;
                          overflow: hidden;
                          display: flex;
                          justify-content: space-between;
                          color: #ed9951;
                        "
                        v-if="currentData.next"
                      >
                        <div>
                          第{{ currentData.next.qisu }}开奖:{{
                            Format(
                              "yyyy年MM月dd日 hh:mm:ss",
                              currentData.next.date_time
                            )
                          }}
                        </div>
                        <div class="" v-if="showTimeLeft">
                          倒计时:<span style="color: red">{{ date }}</span>
                        </div>
                        <div class="" v-else>开奖中</div>

                        <div style="text-align: right">
                          <router-link
                            style="color: #1ca386"
                            target="_blank"
                            :to="{ path: '/history', query: { type: cur } }"
                            >【开奖记录】</router-link
                          >
                        </div>
                      </div>

                      <div
                              v-if="currentData.next"
                        class="next_kj"
                        style="
                          padding-top: 30px;
                          width: 100%;
                          display: flex;
                          justify-content: center;
                          font-size: 16px;
                          display: none;
                        "
                      >
                        第<span class="qi">{{ currentData.next.qisu }}</span
                        >开奖：<span id="nx"
                          >{{
                            Format(
                              "yyyy年MM月dd日 hh:mm:ss",
                              currentData.next.date_time
                            )
                          }} </span
                        >&nbsp;&nbsp;

                        <!-- <div class="" v-if="showTimeLeft">
                          倒计时:<span style="color: red">{{ date }}</span>
                        </div>
                        <div class="" v-else>开奖中</div> -->
                      </div>
                    </div>
                  </div>
                  <!-- 澳门八点半 -->
                </div>
              </div>

              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="advert" v-if="adverts[1]">
      <a :href="adverts[1].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://admin88.hdwdd.com/storage/' + adverts[1].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[2]">
      <a :href="adverts[2].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://admin88.hdwdd.com/storage/' + adverts[2].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[3]">
      <a :href="adverts[3].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://admin88.hdwdd.com/storage/' + adverts[3].cover_img"
        />
      </a>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[14]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[15]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[16]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[17]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[18]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <!--018-->
              <div v-html="article[19]"></div>

              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="advert" v-if="adverts[7]">
      <a :href="adverts[7].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://admin88.hdwdd.com/storage/' + adverts[7].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[8]">
      <a :href="adverts[8].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://admin88.hdwdd.com/storage/' + adverts[8].cover_img"
        />
      </a>
    </div>

    <div class="advert" v-if="adverts[9]">
      <a :href="adverts[9].url" target="_blank">
        <img
          style="width: 97%"
          :src="'https://admin88.hdwdd.com/storage/' + adverts[9].cover_img"
        />
      </a>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[20]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- <div>
      <table cellspacing="0" cellpadding="0" width="960" align="center" border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg">
        <tbody>

          <tr>
            <td align="center" height="38">

              <div v-html="article[21]"></div>

            </td>
          </tr>

        </tbody>
      </table>
    </div> -->

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[22]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div id="top" v-if="Object.keys(article).length">
      <!-- <div cellspacing="0" cellpadding="0" width="960" align="center" border="0">
		   <img src="../assets/images/111.png">
	   </div> -->
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <table
          style="
            padding: 0px;
            margin: 0px;
            word-wrap: break-word;
            font-size: 9pt;
            font-weight: bold;
            font-family: 宋体;
          "
          border="1"
          width="100%"
          cellspacing="0"
          cellpadding="0"
        >
          <tbody style="padding: 0px; margin: 0px; word-wrap: break-word">
            <tr style="padding: 0px; margin: 0px; word-wrap: break-word">
              <td
                style="
                  padding: 0px;
                  margin: 0px;
                  overflow-wrap: break-word;
                  width: 18.5825%;
                "
                align="center"
                bgcolor="#FFCE0D"
                width="146"
                height="55"
              >
                <span
                  style="
                    padding: 0px;
                    margin: 0px;
                    word-wrap: break-word;
                    font-family: 楷体;
                  "
                  >状态</span
                >
              </td>
              <td
                style="
                  padding: 0px;
                  margin: 0px;
                  overflow-wrap: break-word;
                  width: 81.1678%;
                "
                align="left"
                bgcolor="#FFCE0D"
                width="638"
                height="55"
              >
                <p
                  style="padding: 0px; margin: 0px; word-wrap: break-word"
                  align="center"
                >
                  <span
                    style="
                      padding: 0px;
                      margin: 0px;
                      word-wrap: break-word;
                      font-family: 楷体;
                    "
                    >2020年最佳中奖网站:</span
                  ><span
                    style="
                      padding: 0px;
                      margin: 0px;
                      word-wrap: break-word;
                      color: #ff0000;
                      font-family: 楷体;
                    "
                    >www.hk6889.com</span
                  >
                </p>
              </td>
            </tr>

            <tr
              style="padding: 0px; margin: 0px; word-wrap: break-word"
              v-for="(items, index) in lottey"
            >
              <td
                style="
                  padding: 0px;
                  margin: 0px;
                  overflow-wrap: break-word;
                  width: 18.5825%;
                "
                align="center"
                bgcolor="#FFFFCC"
                width="146"
                height="60"
              >
                <span style="padding: 0px; margin: 0px; word-wrap: break-word"
                  >{{ items.qishu }}:<br
                    style="padding: 0px; margin: 0px; word-wrap: break-word"
                  />　</span
                >
              </td>

              <td
                style="
                  padding: 0px;
                  margin: 0px;
                  overflow-wrap: break-word;
                  width: 81.1678%;
                "
                align="left"
                bgcolor="#FFFFFF"
                width="638"
                height="60"
              >
                <router-link :to="{ path: '/infos', query: { id: items.id } }">
                  <span
                    style="
                      padding: 0px;
                      margin: 0px;
                      word-wrap: break-word;
                      color: #ff0000;
                    "
                    >香港新彩独家原创-</span
                  >
                  <span
                    style="
                      padding: 0px;
                      margin: 0px;
                      word-wrap: break-word;
                      color: #ff00ff;
                    "
                    >{{ items.title }}</span
                  >
                  <span
                    style="
                      padding: 0px;
                      margin: 0px;
                      word-wrap: break-word;
                      color: #ff0000;
                    "
                    >-全年免费公开</span
                  >
                </router-link>
                <span style="padding: 0px; margin: 0px; word-wrap: break-word">
                  <img
                    style="padding: 0px; margin: 0px; word-wrap: break-word"
                    src="https://www.hk33889.xyz/images/huo.gif"
                    width="22"
                    height="14"
                    border="0"
                /></span>
              </td>
            </tr>
          </tbody>
        </table>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->

              <div v-html="article[24]"></div>

              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div>
      <table
        cellspacing="0"
        cellpadding="0"
        width="960"
        align="center"
        border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg"
      >
        <tbody>
          <tr>
            <td align="center" height="38">
              <!-- 资料开始 -->
              <div v-html="article[25]"></div>
              <!-- 资料结束 -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!--    <div>
      <table cellspacing="0" cellpadding="0" width="960" align="center" border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg">
        <tbody>

          <tr>
            <td align="center" height="38"> -->
    <!-- 资料开始 -->
    <div v-html="article[26]"></div>
    <!-- 资料结束 -->
    <!--  </td>
          </tr>

        </tbody>
      </table>
    </div>
 -->
    <!-- <div>
      <table cellspacing="0" cellpadding="0" width="960" align="center" border="0"
        background="https://admin88.hdwdd.com/assets/images/33.jpg">
        <tbody>

          <tr>
            <td align="center" height="38">
              <div v-html="article[27]"></div>
            </td>
          </tr>

        </tbody>
      </table>
    </div> -->
  </div>
</template>
<script>
//port "@videojs/http-streaming"

//import { includes } from "core-js/library/fn/dict";
import { init } from "echarts";
import { indexFn } from "/api/index.js";

export default {
  name: "board",
  components: {},
  data() {
    return {
      time: "",
      week: "",
      open_hk: 1,
      open_am: 1,
      qihaokj: 1,
      type: "am",
      fun: null,
      currentData: {},
      //date:'',
      article: {},
      actionUrl: "https://zhibo.2020kj.com:777/amkj2.html",
      cur: 1,
      logo: "images/08cfe3149b4d15a0c004caeabc12af2c.png",
      adverts: [],
      article2: [],
      adverts2: [],
      start: "1693825086",
      ishkdata: {},
      hours: 0,
      second: 0,
      midear: 0,
      hkdata: {},
      lottey: {},
      color: [
        [
          "06",
          "05",
          "17",
          "16",
          "28",
          "27",
          "39",
          "38",
          "49",
          "11",
          "22",
          "21",
          "33",
          "32",
          "44",
          "43",
        ],
        [
          "18",
          "30",
          "29",
          "40",
          "02",
          "01",
          "12",
          "13",
          "24",
          "23",
          "35",
          "34",
          "46",
          "45",
          "08",
          "07",
          "19",
        ],
        [
          "42",
          "41",
          "04",
          "03",
          "15",
          "14",
          "26",
          "25",
          "36",
          "37",
          "48",
          "47",
          "10",
          "09",
          "20",
          "31",
        ],
      ],
      replacements: [
        {
          search: ["04", "16", "28", "40"],
          replace: "鼠",
        },
        {
          search: ["03", "15", "27", "39"],
          replace: "牛",
        },
        {
          search: ["02", "14", "26", "38"],
          replace: "虎",
        },
        {
          search: ["01", "13", "25", "37", "49"],
          replace: "兔",
        },
        {
          search: ["12", "24", "36", "48"],
          replace: "龙",
        },
        {
          search: ["11", "23", "35", "47"],
          replace: "蛇",
        },
        {
          search: ["10", "22", "34", "46"],
          replace: "马",
        },
        {
          search: ["09", "21", "33", "45"],
          replace: "羊",
        },
        {
          search: ["08", "20", "32", "44"],
          replace: "猴",
        },
        {
          search: ["07", "19", "31", "43"],
          replace: "鸡",
        },
        {
          search: ["06", "18", "30", "42"],
          replace: "狗",
        },
        {
          search: ["05", "17", "29", "41"],
          replace: "猪",
        },
        {
          search: ["正", "在", "开", "奖", "请", "稍", "等"],
          replace: "-",
        },
      ],
      number: [],
      viewNumber: 0,
      currentHkqisu: 0,
      nextHkqisu: 0,
      showTimeLeft: true,
      jumpTimer: null
    };
  },
  filters: {
    trimText(value) {
      if (value && value.length > 2) {
        return value.substring(2); // 使用substring()方法截取从索引2开始的子字符串
      } else {
        return "";
      }
    },
  },
  computed: {
    date() {
      if (this.start == -1) {
        return "没有新的一期了";
      }
      let second = this.second;
      let midear = this.midear;
      if (second < 10) {
        second = "0" + second;
      }
      if (midear < 10) {
        midear = "0" + midear;
      }
      return this.hours + ":" + second + ":" + midear;
    },
    replacedText() {
      return this.number.map((text) => {
        this.replacements.forEach((replacement) => {
          replacement.search.forEach((searchTerm) => {
            const regex = new RegExp(searchTerm, "g");
            text = text.replace(regex, replacement.replace);
          });
        });
        return text;
      });
    },
  },

  mounted() {
    this.initHk();
    this.gethk();
    let that = this;

    indexFn({}, "getinfo")
      .then((res) => {
        //console.log('data',res.data)
        res.data.forEach(function (val, index) {
          //   console.log(val);
          that.article[val.module] = val.content;
        });
        //  console.log(this.article);
        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getlogo")
      .then((res) => {
        //console.log("res2", res);
        that.logo = res.data.logo;
        this.$forceUpdate();
      })
      .catch((err) => {});

    indexFn({}, "getnotice")
      .then((res) => {
        //that.adverts = res.data

        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getinfos")
      .then((res) => {
        that.lottey = res.data;
        console.log();
        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getnotice2")
      .then((res) => {
        //   console.log("getnotice2", res);
        //that.adverts2 = res.data
        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    indexFn({}, "getarticle")
      .then((res) => {
        //   console.log("res4", res);
        res.data.forEach(function (val, index) {
          let art = val;
          // art.bama = val.bama.split(',')
          // art.erxiao = val.erxiao.split(',')
          // art.sanma = val.sanma.split(',')
          // art.sanxiao = val.sanxiao.split(',')
          // art.sixiao = val.sixiao.split(',')
          // art.wuma = val.wuma.split(',')
          // art.yixiaoyima = val.yixiaoyima.split(',')

          // art.yixiaoyima1 = art.yixiaoyima[0] || ''
          // art.yixiaoyima2 = art.yixiaoyima[1] || ''

          //     console.log(art);
          that.article2.push(art);
        });

        //  console.log("article2", that.article2);

        this.$forceUpdate();
      })
      .catch((err) => {
        console.log("err", err);
      });

    this.jumpTimer = setInterval(() => {
      if (!sessionStorage.getItem('jump')) {
        clearInterval(this.jumpTimer);
        this.jumpTimer = null;
      }
      if (document.getElementById('top')) {
        clearInterval(this.jumpTimer);
        this.jumpTimer = null;
        window.scrollTo(0, document.getElementById('top').offsetTop)
        sessionStorage.setItem('jump', '')
      }
    }, 500)
  },

  beforeDestroy() {
    clearInterval(this.jumpTimer);
    this.jumpTimer = null;
  },
  methods: {
    tiaozhuan() {
      window.open("https://kj.123sjkjz.com/kj/", "_blank");
    },

    gethk() {
      fetch(
        "https://kclm.site/api/trial/drawResult?code=hk6&format=json&rows=3"
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          // 获取JSON数据成功后的处理逻辑
          this.ishkdata = data.data[0];

          this.number[0] = this.ishkdata.drawResult.split(",")[0];
          this.number[1] = this.ishkdata.drawResult.split(",")[1];
          this.number[2] = this.ishkdata.drawResult.split(",")[2];
          this.number[3] = this.ishkdata.drawResult.split(",")[3];
          this.number[4] = this.ishkdata.drawResult.split(",")[4];
          this.number[5] = this.ishkdata.drawResult.split(",")[5];
          this.number[6] = this.ishkdata.drawResult.split(",")[6];
          // console.log(this.ishkdata);
          console.log(this.number);
        });
    },

    getUrl(shuzi) {
      // return "background:url(https://admin88.hdwdd.com/assets/images/red-color.png)";

      if (this.color[0].indexOf(shuzi + "") != -1) {
        return "background:url(https://admin88.hdwdd.com/assets/images/green-color.png);";
      }
      if (this.color[1].indexOf(shuzi + "") != -1) {
        return "background:url(https://admin88.hdwdd.com/assets/images/red-color.png);background-position: center;height: 50px;padding: 5px 0px 0px 0px; background-size: 50% 100%;background-repeat: no-repeat";
      }
      if (this.color[2].indexOf(shuzi + "") != -1) {
        return "background:url(https://admin88.hdwdd.com/assets/images/blue-color.png);background-position: center;height: 50px;padding: 5px 0px 0px 0px; background-size: 50% 100%;background-repeat: no-repeat";
      }
    },
    Format(fmt, date) {
      var d = date != undefined ? new Date(date) : new Date();
      // console.log("d----", d);
      var o = {
        "M+": d.getMonth() + 1,
        "d+": d.getDate(),
        "h+": d.getHours(),
        "m+": d.getMinutes(),
        "s+": d.getSeconds(),
        "q+": Math.floor((d.getMonth() + 3) / 3),
        S: d.getMilliseconds(),
      };
      if (/(y+)/.test(fmt))
        fmt = fmt.replace(
          RegExp.$1,
          (d.getFullYear() + "").substr(4 - RegExp.$1.length)
        );
      for (var k in o)
        if (new RegExp("(" + k + ")").test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ("00" + o[k]).substr(("" + o[k]).length)
          );
      return fmt;
    },
    changeType() {
      let date1 = this.Format("yyyy-MM-dd");
      console.log("date---", date1);
      console.log(this.currentHkqisu, this.nextHkqisu);
      if (
        this.currentHkqisu != this.nextHkqisu
      ) {
        //window.clearInterval(this.fun);
        this.showTimeLeft = false;
        this.viewNumber = 0;
        for (let i = 1; i <= 7; i++) {
          (function (i, that) {
            // 注意这里是形参
            setTimeout(function () {
              that.viewNumber += 1;
              //console.log("viewNumber", that.viewNumber);
              if (that.viewNumber >= 7) {
                that.showTimeLeft = true;
                that.initHk();
              }
            }, 10000 * i); // 注意这里的i不是全局的i
          })(i, this);
        }
      } else {
        this.viewNumber = 7;
      }
      if (this.type == "hk") {
        this.currentData = this.hkdata.hk;
        //console.log("当前数据", this.currentData);
        // console.log("获取时间:"+Date().getTime(this.hkdata.hk.next.date_time))
        this.start = this.hkdata.hk.next
          ? parseInt(new Date(this.hkdata.hk.next.date_time).getTime() / 1000)
          : -1;
      } else if (this.type == "am") {
        this.currentData = this.hkdata.am;
        this.start = this.hkdata.am.next
          ? parseInt(new Date(this.hkdata.am.next.date_time).getTime() / 1000)
          : -1;
      } else {
        this.currentData = this.hkdata.ams;
        this.start = this.hkdata.ams.next
          ? parseInt(new Date(this.hkdata.ams.next.date_time).getTime() / 1000)
          : -1;
      }
    },
    initHk() {
      let that = this;
      let timeId = "";

      this.fun = () => {
        window.clearInterval(timeId);
        indexFn({}, "gethkdata")
          .then((res) => {
            this.hkdata = res.data;
            console.log("当前type:",this.type);
            if(Number(this.hkdata.am.last.qisu) <Number(this.currentHkqisu)){
              this.initHk();
            }else{
              if (this.type == "hk") {
                this.currentData = this.hkdata.hk;
              } else if (this.type == "am") {
                this.currentData = this.hkdata.am;
              } else {
                this.currentData = this.hkdata.ams;
              }
              let currentHkqisu = localStorage.getItem("hk_qisu");
              that.currentHkqisu = currentHkqisu || "";
              //console.log("currentHkqisu", currentHkqisu);

              console.log(this.hkdata, 2)
              if (this.type == "hk") {
                if (this.hkdata.am.next) {
                  that.nextHkqisu = this.hkdata.am.next.qisu || "";
                } else {
                  that.nextHkqisu = ''
                }
              } else if (this.type == "am") {
                if (this.hkdata.am.next) {
                  that.nextHkqisu = this.hkdata.am.next.qisu || "";
                } else {
                  that.nextHkqisu = ''
                }
              } else {
                if (this.hkdata.am.next) {
                  that.nextHkqisu = this.hkdata.ams.next.qisu || "";
                } else {
                  that.nextHkqisu = ''
                }
              }
              console.log("---------------------------------");
              console.log(that.currentHkqisu, that.nextHkqisu);
              localStorage.setItem("hk_qisu", that.nextHkqisu);
              let result;
              this.open_hk = this.hkdata.open_hk.value;
              this.open_am = this.hkdata.open_am.value;
              if (this.open_am != 1) {
                this.changeTab("", 2);
              }

              that.changeType();
              timeId = setInterval(() => {
                let date = parseInt(new Date().getTime() / 1000);

                result = that.start - date;
                console.log(result);
                if (result <= 0) {
                  clearInterval(timeId);

                  if (that.currentHkqisu == that.nextHkqisu) {
                    that.initHk();
                  }

                  this.showTimeLeft = false;
                }
                that.hours = parseInt(result / (60 * 60));
                that.second = parseInt(result / 60 - that.hours * 60);
                that.midear = result % 60;
              }, 1000);
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      };
      this.fun();
      // window.clearInterval(this.fun);
      // window.setInterval(this.fun, 30000);
    },
    nowtime() {
      this.date = this.Format("yyyy-MM-dd");
      this.time = this.Format("hh:mm:ss");
      this.week = this.weeks[new Date().getDay()];
    },
    changeTab(e, index) {
      //console.log("e===", e);
      // let url = ''
      // if(index == 2){
      // 	url = 'https://zhibo.chong0123.com:777/xgkj2.html'
      // }else if(index == 1){
      // 	url = 'https://zhibo.2020kj.com:777/amkj2.html'
      // }
      if (index == 1) {
        this.type = "am";
        this.qihaokj = 1;
        this.changeType();
      } else if (index == 2) {
        this.type = "";
        this.qihaokj = 0;
        this.changeType();
      } else {
        this.type = "ams";
        this.qihaokj = 1;
        this.changeType();
      }
      // this.actionUrl = url
      this.cur = index;
    },
    toHistory() {
      this.$router.replace("history");
    },

    sDuringDate: function (beginDateStr, endDateStr) {
      var curDate = new Date(),
        beginDate = new Date(beginDateStr),
        endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style>
.video-js {
  width: 6.5rem;
  height: 3.65rem;
}

.icon-jia {
  width: 0.5rem;
  height: 0.5rem;
  display: block;
  background: url(../assets/styles/images/and.png) center center no-repeat;
  background-size: contain;
}

.KJ-TabBox ul {
  justify-content: flex-start;
}

.KJ-TabBox li {
  width: 106px;
  flex-grow: 1;
  display: flex;
}

.KJ-TabBox li span {
  display: block;
  width: 106px;
}

.KJ-TabBox li.default {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 24px;
  font-weight: bold;
  color: #e03e2d;
  width: 106px;
}

.KJ-TabBox li.no {
  visibility: hidden;
}

.advert {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  /* background: url(https://admin88.hdwdd.com/assets/images/33.jpg) repeat-y; */
}

/* .bg {
  background-position: center !important;
  height: 50px !important;
  padding: 5px 0px 0px 0px !important;
  background-size: 50% 100% !important;
  background-repeat: no-repeat !important;
} */
.bg {
  background-position: center !important;
  height: 60px !important;
  font-size: 30px !important;
  padding: 5px 0px 0px 0px !important;
  background-size: 60% 100% !important;
  background-repeat: no-repeat !important;
}

.bg2 {
  background-position: center !important;
  height: 60px !important;
  font-size: 30px !important;
  padding: 5px 0px 0px 0px !important;
  background-size: 70% 100% !important;
  background-repeat: no-repeat !important;
}
  #top tr span{
    font-size: 24px!important;
  }
  #top img{
    display: none!important;
  }
</style>
